import React, { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { TextField, Button } from "@mui/material";
import { StaticTimePicker } from "@mui/x-date-pickers/StaticTimePicker";
import dayjs from "dayjs";

function BookTableSanctuary() {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [timeConfirmed, setTimeConfirmed] = useState(false);

  const handleDateChange = (newValue) => {
    setSelectedDate(newValue);
  };

  const [selectedTime, setSelectedTime] = useState(dayjs());

  const handleTimeChange = (newTime) => {
    console.log("TimeCahnge");
    setSelectedTime(newTime);
  };

  const confirmTime = () => {
    setTimeConfirmed(true);
    console.log("Heure sélectionnée:", selectedTime.toLocaleTimeString());
    // Vous pouvez également effectuer d'autres actions ici, comme fermer le picker
  };

  console.log(selectedDate.$d);
  console.log(selectedTime.$d.getHours());
  console.log(selectedTime.$d.getMinutes());

  return (
    <>
      <div></div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar onChange={handleDateChange} />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <StaticTimePicker
            orientation="landscape"
            displayStaticWrapperAs="mobile"
            value={selectedTime}
            onChange={handleTimeChange}
            renderInput={(params) => <TextField {...params} />}
            ampm={false} // Configure pour utiliser le format 24 heures
          />
        </LocalizationProvider>
        <Button onClick={confirmTime} color="primary" variant="contained">
          Confirmer l'heure
        </Button>
      </div>

      <div> </div>
    </>
  );
}

export default BookTableSanctuary;
