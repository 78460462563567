import React, { useState } from "react";
import Registration from "../Authentification/Authentification";

const Header = ({ menuChoice, setmenuChoice }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
  const divStyle = {
    backgroundImage: "url(/images/Couv.png)",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    height: "100%",
    width: "100%",
    gridColumn: "span 3", // Span all three columns
    gridRow: "span 1",
    display: "flex", // Utiliser flexbox pour aligner les éléments horizontalement
    justifyContent: "space-between",
    padding: "0 20px", // Optionnel : ajout d'un padding pour éloigner les éléments des bords
  };

  return (
    <div style={divStyle}>
      <Registration />
      {/*<div style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{
            maxHeight: "50px",
            background: "rgba(251,247,239,0.8)",
            padding: "20px",
            borderRadius: "10px",
            margin: "15px",
            display: "flex",
            flexDirection: "row",
            marginRight: "50px",
            alignItems: "center",
          }}
        >
          <div
            onMouseEnter={() => setIsHovered(true)} // Lorsque la souris entre dans le div
            onMouseLeave={() => setIsHovered(false)} //
            style={{
              borderBottom: "1px solid black",
              marginRight: "10px",
              padding: "15px",
              borderRadius: "10px",
              backgroundColor: isHovered ? "#e9A03d" : "transparent",
              cursor: "pointer",
            }}
            onClick={() => setmenuChoice(0)}
          >
            Referral
          </div>
          <div
            onMouseEnter={() => setIsHovered3(true)} // Lorsque la souris entre dans le div
            onMouseLeave={() => setIsHovered3(false)} //
            style={{
              borderBottom: "1px solid black",
              marginRight: "10px",
              padding: "15px",
              borderRadius: "10px",
              backgroundColor: isHovered3 ? "#e9A03d" : "transparent",
            }}
            onClick={() => setmenuChoice(2)}
          >
            Events
          </div>
          <div
            onMouseEnter={() => setIsHovered2(true)} // Lorsque la souris entre dans le div
            onMouseLeave={() => setIsHovered2(false)} //
            style={{
              borderBottom: "1px solid black",
              marginRight: "10px",
              padding: "15px",
              borderRadius: "10px",
              backgroundColor: isHovered2 ? "#e9A03d" : "transparent",
            }}
            onClick={() => setmenuChoice(1)}
          >
            Sanctuary booking
          </div>
        </div>
        <div
          style={{
            maxHeight: "50px",
            background: "rgba(255, 255, 255, 0.8)", // Blanc avec 80 % d'opacité
            padding: "20px",
            borderRadius: "10px",
            margin: "15px",
            display: "flex",
            flexDirection: "row",
            marginRight: "50px",
            alignItems: "center",
          }}
        >
          <div
            style={{
              marginRight: "10px",
              margingLeft: "10px",
              height: "30px",
              width: "30px",
              padding: "20px",
              borderRadius: "50%",
              border: "1px solid black",
            }}
          ></div>
        </div>
      </div>*/}
    </div>
  );
};

export default Header;
