import React, { useState } from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import LogIn from "./LogIn/LogIn";

import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebaseConfig";
import { signOut } from "firebase/auth";

const Registration = () => {
  const [user, loading, error] = useAuthState(auth);
  const logout = () => {
    signOut(auth);
    window.location.reload();
  };

  const logoutButtonStyle = {
    backgroundColor: "black",
    borderRadius: "15px", // Ajout du border-radius
    maxHeight: "55px",
    margin: "15px",
    padding: "10px",
    color: "#a62503",
    transition: "background-color 0.3s ease", // Ajout de l'effet de transition
    "&:hover": {
      backgroundColor: "darkgray", // Couleur de fond au survol
    },
  };
  return (
    <>
      {user ? (
        <Button onClick={logout} sx={logoutButtonStyle}>
          Log Out
        </Button>
      ) : (
        <LogIn />
      )}
    </>
  );
};

export default Registration;
