import "./MenuQuestion2options.css";
import React, { useState } from "react";

function MenuQuestion2options({ menuChoice, setmenuChoice }) {
  return (
    <>
      <div></div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "3rem",
          fontWeight: "normal",
        }}
      >
        Would you prefer to
        <span
          style={{
            fontWeight: "bold",
            fontSize: "4rem",
            textDecoration: "underline",
            marginLeft: "10px",
            marginRight: "10px",
          }}
          onClick={() => setmenuChoice(1)}
        >
          reserve a table
        </span>{" "}
        at The Sanctuary,or attend the{" "}
        <span
          style={{
            fontWeight: "bold",
            fontSize: "4rem",
            textDecoration: "underline",
            marginLeft: "10px",
            marginRight: "10px",
          }}
          onClick={() => setmenuChoice(2)}
        >
          upcoming party?
        </span>
      </div>

      <div></div>
    </>
  );
}

export default MenuQuestion2options;
