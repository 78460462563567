import "./App.css";
import React, { useState } from "react";
import MainDashNSFW from "./components/MainDash/MainDashNSFW/MainDashNSFW";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./firebaseConfig";
import Registration from "./components/Authentification/Authentification";
import NoLogInPage from "./components/NoLogInPage/NoLogInPage";
import Header from "./components/Header/Header";
import ProfilePage from "./components/ProfilePage/ProfilePage";
import MenuQuestion2options from "./components/MenuQuestion2options/MenuQuestion2options";
import BookTableSanctuary from "./components/BookTableSanctuary/BookTableSanctuary";

function App() {
  const [user, loading, error] = useAuthState(auth);
  const [menuChoice, setmenuChoice] = useState(2);
  return (
    <div className="App">
      {user ? (
        <div className="AppGlass">
          <Header menuChoice={menuChoice} setmenuChoice={setmenuChoice} />

          {user ? (
            <>
              {menuChoice === 0 && (
                <MenuQuestion2options
                  menuChoice={menuChoice}
                  setmenuChoice={setmenuChoice}
                />
              )}

              {menuChoice === 1 && <BookTableSanctuary />}

              {menuChoice === 2 && <MainDashNSFW />}

              {/* <div className="test1"> </div>
              <div className="test2">
                {" "}
                {menu === 1 ? <MainDashNSFW /> : <ProfilePage />}
              </div>
              <div className="test3"> </div>

              <div>{/*<StripeContainer /></div>*/}
              {/*<RightSidePartyRegistration />*/}
            </>
          ) : (
            <>
              {/*<LogIn />*/}
              {/*<CreateUser />*/}
            </>
          )}
        </div>
      ) : (
        <NoLogInPage />
      )}
    </div>
  );
}

export default App;
