import React, { useState } from "react";
import "./MainDashNSFW.css";
import Calendarregistration from "../../Calendarregistration/Calendarregistration";
import DesignMoreInfoParty from "../../DesignMoreInfoParty/DesignMoreInfoParty";

export default function MainDashNSFW() {
  return (
    <>
      <div></div>
      <div className="MainDash">
        <div className="DisplayParties">
          {/*<Calendarregistration
            ImgURL={"/images/Temple-Night.png"}
            Title={"TEMPLE NIGHT - 21/09/2024"}
            TypeFormLink={"https://44nqyr83x8g.typeform.com/to/E7c518Io"}
            SmallDescription={
              "Our Tantric Experience - Discover vivid vibration and energetic tingling, and enjoy deep connection in the moment."
            }
            LongDescription={
              <DesignMoreInfoParty
                ImgURL={"/images/Temple-Night.png"}
                A={
                  "Discover vivid vibration and energetic tingling, and enjoy deep connection in the moment. Surrender to love & eros and free your ecstatic flow. Meet new people mindfully and immerse yourself in nurturing sensuality. We invite you to an evening of wonderful dear souls, embodied connection exercises and nourishing exploratory space, deepening our presence, love, heart connections, freedom, authenticity, sensuality and playfulness."
                }
                B={
                  "This evening is a mix of a guided workshop and free exploration time."
                }
                C={"What is a Temple Night?"}
                D={
                  "Temple Nights are based on ancient tantric traditions where we gather in a sacred space to celebrate and worship love, heart space, our sensuality, each other and our connection to the divine."
                }
                E={"What is the evening like?"}
                F={
                  "We will start by creating a safe space together and then get to know each other in the opening circle. You will then playfully get to know other participants in guided exercises and can meet them with your authentic desires, needs and boundaries. Finally, we will open the sacred playground and you will be able to explore heart-connected intimacy. "
                }
                G={"Who is the workshop for?"}
                H={
                  "This event is for people of all levels of experience. Those who are curious about tantra or a temple night but have never experienced something like this before or have little experience. And also those, who have had some experience on temple nights & in sexpositive spaces."
                }
                I={"What will I get out of the workshop?"}
                J={
                  "* Ice breaker games to get to know your counterpart authentically * Practising your mindfulness in encounter * Awareness of needs, wishes and limits of you & your partner * Boundary experiences in a safe setting that you can create for yourself in order to grow from them * Activation and exploration of your love & sensuality"
                }
                K={"Event details:"}
                L={
                  "* Facilitation by Anthony from Experiencesssssss * Please note this is an alcohol and substance free event * Saturday September 21th, 2024 from 9:00PM to early morning. Doors are open from 9:00PM to 9:30PM. * Private venue next to Saint-Michel, Paris 6e * Ticket: 90€ - Includes unlimited non-alcoholic drinks and sweet buffet * Limited capacity: 20 pers."
                }
                M={""}
                N={""}
                O={""}
                P={""}
                Q={""}
              />
            }
          />

          <Calendarregistration
            ImgURL={"/images/Liquid-Love.jpg"}
            Title={"PURPLE LIQUID LOVE - 26/09/2024"}
            TypeFormLink={"https://44nqyr83x8g.typeform.com/to/SSbmViuA"}
            SmallDescription={
              "Immerse yourself in a sensorial and sensual group experience and explore the skin-to-skin contact flow of bodies covered in oil."
            }
            LongDescription={
              <DesignMoreInfoParty
                ImgURL={"/images/Liquid-Love.jpg"}
                A={"WHAT IS A LIQUID LOVE?"}
                B={
                  "Liquid Love is a sensorial and sensual group experience in which the participants are guided in exploring their bodies and their boundaries."
                }
                C={
                  "You'll be invited, along with twenty other people, to cover yourself in oil for a beautiful journey, exploring intimacy, connection, surrender, playfulness, and the sensuality of flowing skin-to-skin contact. "
                }
                D={
                  "Once in the oil, we invite you to move and allow yourself to be moved without intentions or sexual orientation. Releasing our usual desire to take action on erotic sensations and thoughts, we invite you to be present with them and to relax into the flow of the bodies around you. This is a place where you can love and be loved in an impersonal and unbound manner and above all a place to super charge your soul."
                }
                E={
                  "WHAT HAPPENS AT AN AKASHA PURPLE LIQUID LOVE SESSION? DO PEOPLE HAVE SEX AT AKASHA LIQUID LOVE? WHAT SHOULD I BRING? AND MORE..."
                }
                F={
                  <div>
                    'To ensure you have all of the information you need, we
                    invite you to take a few minutes to read "Everything you
                    need to know about Akasha Purple Liquid Love".'
                    <a>click here</a>
                  </div>
                }
                G={"Event details: "}
                H={
                  "* Thursday September 26th, 2024 from 8:00 to 11:59PM. Doors are open from 8:00 to 8:30PM"
                }
                I={"* Private venue next to Saint-Michel, Paris 6e"}
                J={
                  "* Early Bird / Regular: 90€ / 110€ - Includes unlimited non-alcoholic drinks and sweet buffet"
                }
                K={"* Please note this is an alcohol and substance free event"}
                L={"* Limited capacity: 20 pers."}
                M={""}
                N={""}
                O={""}
                P={""}
                Q={""}
              />
            }
          />
          <Calendarregistration
            ImgURL={"/images/Shibari.png"}
            Title={"SHIBARI WORKSHOP - 29/09/2024"}
            TypeFormLink={"https://44nqyr83x8g.typeform.com/to/R8WK38Lg"}
            SmallDescription={
              "Dive into the art of Shibari, a mesmerising blend of aesthetics and connection, with our hands-on introductory rope workshop."
            }
            LongDescription={
              <DesignMoreInfoParty
                ImgURL={"/images/Shibari.png"}
                A={
                  "Dive into the art of Shibari, a mesmerising blend of aesthetics and connection, with our hands-on introductory workshop. Whether you’re seeking a new form of expressive connection or to learn ways to delve more into your kinkiness, our expert-guided session is perfect for you!"
                }
                B={"🪢 What to Expect 🪢 "}
                C={
                  "* Workshop : Explore the origins of Shibari, learn about the essential safety precautions and engage in a hands-on initiation tutorial."
                }
                D={
                  "* Performance: Watch a captivating performance by Kevin and Marine. "
                }
                E={
                  "* Open Q&A: Unravel any curiosities with our seasoned instructor."
                }
                F={
                  "* Tea time snack: Some juices and sweet treats will be available for your delights. "
                }
                G={
                  "Join us for an afternoon of delicate artistry, intimate connection, and exciting learning. This experience is suitable for duos, and no prior experience is necessary. Just bring an open mind and a flair for fun!"
                }
                H={
                  "If you have already joined an initiation Shibari class, feel free to join again as Kevin is happy to teach additional knots to further your rope skills."
                }
                I={"📖 Instructor Bio 📖"}
                J={
                  "Kevin is a passionate Shibari rigger and BDSM practitioner based in Paris. Performing with his partner and model Marine to many events around Europe for a few years, he has also decided to pass on his passion and knowledge to people eager to learn. Kevin believes in the power of Shibari to foster deep connections, both with oneself and others."
                }
                K={"💬 Additional Details 💬"}
                L={"Number of participants: max 8 duos. "}
                M={"Event Schedule: "}
                N={
                  "4:00 PM - 4:30 PM: Beginning of the event and tea time / social time.  "
                }
                O={"4:30 PM - 6:30 PM: Workshop, followed by Q&A. "}
                P={"6:30 PM - 7:00 PM: Performance by Kevin and Marine."}
                Q={"7:30 PM: End of the event and final departures."}
                R={"Price: 80 euros for 2 people. "}
              />
            }
          />*/}

          <Calendarregistration
            ImgURL={"/images/PLAYDATE.png"}
            Title={"PLAYDATE PARTY - 05/10/2024"}
            TypeFormLink={"https://44nqyr83x8g.typeform.com/to/Ku7EFvup"}
            SmallDescription={
              "Come spend the night with us and allow yourself to play, connect and explore in a safer, respectful and inclusive space."
            }
            LongDescription={
              <DesignMoreInfoParty
                ImgURL={"/images/PLAYDATE.png"}
                A={
                  "Have you ever wanted to break free? Open a window into our world where you’ll find other friends and potential loves. Play games and meet new people. Real a fantasy and connect in a low pressure environment."
                }
                B={
                  "During this adventure, members will be welcome to explore in a safe, respectful and inclusive space. There will be plenty of room for meeting other members and enjoying the evening at your own pace. Dress and play to your level of enjoyment."
                }
                C={
                  "Please note that what makes our parties so special is our ability to find the right combination of people coming to an event. Therefore, your pre-registration does not guarantee your access to the party, you will shortly receive a confirmation email or a reimbursement following your pre-registration."
                }
                D={
                  "Venue: Haussmannien apartment located in Paris 6. * Early bird ticket price: 75 euros * Regular ticket price: 90 euros Enjoy one complimentary alcoholic drink and a sweet buffet included in your entrance fee."
                }
                E={""}
                F={""}
                G={""}
                H={""}
                I={""}
                J={""}
                K={""}
                L={""}
                M={""}
                N={""}
                O={""}
                P={""}
                Q={""}
              />
            }
          />

          <Calendarregistration
            ImgURL={"/images/PolyConnect.jpg"}
            Title={"POLYCONNECT AFTERWORK - 10/10/2024"}
            TypeFormLink={"https://44nqyr83x8g.typeform.com/to/efsUdtSj"}
            SmallDescription={
              "Whether you are curious about open relating or polyamory, or whether you are already experienced, it's invaluable to be able to share and connect with like-minded people in this exploration."
            }
            LongDescription={
              <DesignMoreInfoParty
                ImgURL={"/images/PolyConnect.jpg"}
                A={
                  "Open relating, non-monogamy, polyamory, exploration of freedom in your relationship. Perhaps you are curious, or maybe you are already very experienced. Whatever your experience, it's invaluable to be able to share and connect with like-minded people in this exploration of polyamory. This evening we'll question ourselves, and have fun while we're at it. During this afterwork, we will guide you through a series of subjects to think about your own place and ideas within non-monogamous relating. We'll take these insights as a guideline for conversation to share and connect with each other."
                }
                B={
                  "What non-monogamy style suits you? What are you running into? What do you do in certain situations? An interactive evening where you're invited to reflect, share and listen. An evening full of possible new solutions, recognition, tips, inspiration and connection."
                }
                C={
                  "We truly believe that no matter what non-monogamous path you are on, a support network of like-minded people is indispensable. Non-monogamous relationships are by far a minority and there are few examples in the media (let alone healthy examples), so we need to find them ourselves. Sharing our non-monogamous experiences, with all the possible challenges, successes and possibilities, with fellow non-monogamous explorers is of immense value."
                }
                D={"Event details: "}
                E={"Thursday October 10, 2024 from 7:00PM to 11:00PM"}
                F={"Private venue next to Saint-Michel, Paris 6e"}
                G={"Free entrance"}
                H={"Bar: cash or card"}
                I={""}
                J={""}
                K={""}
                L={""}
                M={""}
                N={""}
                O={""}
                P={""}
                Q={""}
              />
            }
          />

          {/* <Calendarregistration
            ImgURL={"/images/Sweet.png"}
            Title={"SWEET&VICIOUS PARTY - 19/10/2024"}
            TypeFormLink={"https://44nqyr83x8g.typeform.com/to/eJTco579"}
            SmallDescription={
              "Akasha Society infamous BDSM party specially crafted for all the kinky creatures of the night."
            }
            LongDescription={
              <DesignMoreInfoParty
                ImgURL={"/images/Sweet.png"}
                A={
                  "Either that you wanna feel dominant or submissive, sadist or masochist, passionate, devoted, fulfilled, watched and envied, playful or desired, come spend the night with us on the kinky side of the force."
                }
                B={
                  "We also provide during this event a “Board of Desires” where you will be able to write all the kinky ideas you would like to receive or offer during the night. Let your imagination and desires run free!"
                }
                C={
                  "Our BDSM parties are open to people experimented, as well as to people starting their kinky journey but that are curious and aware of the kind of practices that BDSM includes."
                }
                D={"Program: "}
                E={"* More info coming soon"}
                F={"Event details: "}
                G={
                  "* Saturday October 19th, 2024 from 9:30PM to early morning. Doors are open from 9:30PM to 10:30PM."
                }
                H={"* Private venue next to Saint-Michel, Paris 6e"}
                I={
                  "* Early Bird / Regular: 75€ / 90€ - Includes one drink and a sweet buffet"
                }
                J={""}
                K={""}
                L={""}
                M={""}
                N={""}
                O={""}
                P={""}
                Q={""}
              />
            }
          />

          <Calendarregistration
          ImgURL={"/images/LoveS.jpg"}
          Title={"Love Society - 20/01/2024"}
          TypeFormLink={"https://44nqyr83x8g.typeform.com/to/A0Y7f24P"}
          SmallDescription={
            "Join us for a delightful night of sensuality, where exploration, desire, and human connection merge in a safe and intimate space."
          }
          LongDescription={
            <DesignMoreInfoParty
              ImgURL={"/images/LoveS.jpg"}
              A={
                "Join us for a delightful night of sensuality, where exploration, desire, and human connection merge in a safe and intimate space. Akasha Society collaborates with Love Experience to offer you this new experience. "
              }
              B={"📜 What to Expect 📜"}
              C={
                "🌹 Facilitation by Love Experience: Engage in the opening circle, participate in progressive connection workshops, and freely explore throughout the evening."
              }
              D={
                "🌌 Emotional Connection: Create deep and authentic connections with other participants, share experiences, and explore sexuality in a safe and respectful space."
              }
              E={
                "🏯 Unique Universe: Dive into immersive settings designed to carry you to a world where your desires take center stage. Akasha Society will welcome you into a Haussmannian apartment in the heart of Paris, featuring a lounge and three playrooms."
              }
              F={
                "🍸 Bar and Sweet Buffet: Awaken your senses with a cocktail or indulge in the chocolate fountain and other sweet treats."
              }
              G={"💌 Registration 💌"}
              H={
                "If you wish to participate, please fill out the pre-registration form."
              }
              I={
                "Please note that what makes our parties so special is our ability to find the right combination of people coming to an event. Therefore, your pre-registration does not guarantee your access to the party, you will shortly receive a confirmation email or a reimbursement following your pre-registration."
              }
              J={"💬 Additional Details 💬"}
              K={
                "Number of participants and status: maximum 45 people. As this event is organized in collaboration with Love Experience, please note that this party is not exclusively reserved for Akasha members."
              }
              L={"Event Schedule:"}
              M={
                "9:30 PM - 10:30 PM: Doors open.  Latecomers will not be accepted, as it is necessary for everyone to be able to participate in the opening circle where the framework is set.10:45 PM - 11:15 PM: Opening circle: introductions, intentions setting, and reminder of the rules of the event.11:15 PM - 12:00 AM: Guided progressive connection games.12:00 AM - 5:00 AM: Unguided exploration time.5:00 AM: End of the event and final departures."
              }
              N={"Price: "}
              O={
                "* Early bird ticket price: 75 euros *Regular ticket price: 90 euros Enjoy one complimentary alcoholic drink and a sweet buffet included in your entrance fee."
              }
              P={
                "Important: This event promotes consent, respect for others, and freedom of exploration. All participants must adhere to the event rules, which will be communicated to them in advance to ensure a safe experience for all. "
              }
              Q={""}
            />
          }
        />*/}

          {/* <Calendarregistration
          ImgURL={"/images/Marie-Sauvage.jpg"}
          Title={"Marie Sauvage x Akasha Society Party - 25/05/2024"}
          TypeFormLink={"https://44nqyr83x8g.typeform.com/to/FwRjAqUm"}
          SmallDescription={
            "Experience an extraordinary fusion of art and sensuality at our upcoming party in collaboration with world's renowned Shibari artist, Marie Sauvage. "
          }
          LongDescription={
            <DesignMoreInfoParty
              ImgURL={"/images/Marie-Sauvage.jpg"}
              A={
                "Experience an extraordinary fusion of art and sensuality at our upcoming party in collaboration with world's renowned Shibari artist, Marie Sauvage."
              }
              B={
                "Immerse yourself in an evening of connection and creativity as Marie showcases her enchanting rope bondage techniques, weaving intricate patterns that captivate the senses."
              }
              C={"Event details: "}
              D={
                "A party hosted by @Akasha.Society Saturday May 25th, 2024 from 9:30PM to 6:00AM Shibari performance by Marie Sauvage at 11:00PM Private venue next to Saint-Germain-dès-Près, Paris 6e Early Bird / Regular : 100€ / 120€ - Includes 1 cocktail and sweet buffet "
              }
              E={
                "This is an intimate gathering, therefore only a small amount of tickets will be released."
              }
              F={""}
              G={""}
              H={""}
              I={""}
              J={""}
              K={""}
              L={""}
              M={""}
              N={""}
              O={""}
              P={""}
              Q={""}
            />
          }
        />*/}

          {/*<Calendarregistration
          ImgURL={""}
          Title={"We will be back in September !"}
          TypeFormLink={""}
          SmallDescription={
            "Akasha is taking a break for the summer. We will be back in September with new events and surprises. Stay tuned!"
          }
          LongDescription={
            <DesignMoreInfoParty
              ImgURL={""}
              A={""}
              B={""}
              C={""}
              D={""}
              E={""}
              F={""}
              G={""}
              H={""}
              I={""}
              J={""}
              K={""}
              L={""}
              M={""}
              N={""}
              O={""}
              P={""}
              Q={""}
            />
          }
        />*/}
        </div>

        <div></div>
      </div>
    </>
  );
}
